import { useTransform, useScroll, motion } from "framer-motion"
import WEB from "../../assets/images/websiteDev.jpg"
import ACCESSIBILITY from "../../assets/images/accessibility.png"
import DIGITAL from "../../assets/images/digital.jpg"
import AI from "../../assets/images/AI.jpg"
import MOBILE from "../../assets/images/mobileApp.jpg"
import { useRef } from "react"
import AnimatedText from "../../components/AnimatedText"

const Services = () => {
  const container = useRef(null)
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  })

  return (
    <div ref={container}>
      <div className='ms-8 4k:ms-24 text-transparent text-stroke-orange'>
        <AnimatedText children="OUR SERVICES" />
      </div>
        {projects.map((project, i) => {
          const tragetScale = 1 - ((projects.length - i) * 0.05)
          return <Card key={`p_${i}`} i={i} {...project} progress={scrollYProgress} range={[i * 0.25, 1]} tragetScale={tragetScale} />
        })}  
    </div>
  )
}

export default Services;

const projects = [
  {
    title: "Website Development",
    description:
      "Experience the transformative power of a compelling website that breathes life into your brand. Embrace the digital age with top-tier website development services designed to elevate your online presence.",
    src: WEB,
    color: "#BBACAF",
  },
  {
    title: "Mobile App Development",
    description:
      "Stay connected with your customers on the go with our mobile app development services, designed to enhance user engagement and satisfaction.",
    src: MOBILE,
    color: "#977F6D",
  },
  {
    title: "Artificial Intelligence",
    description:
      "Leverage the power of AI to transform your business with our cutting-edge solutions. Drive efficiency, enhance user experiences, and gain valuable insights with our AI-driven services.",
    src: AI,
    color: "#C2491D",
  },
  {
    title: "Digitial Marketing",
    description:
      "Let our digital marketing services guide you through the vast world of commerce, leading you to unparalleled success. Embrace the future with the insights of the past, and watch your brand soar to new heights.",
    src: DIGITAL,
    color: "#BBACAF",
  },
  {
    title: "Accessbility",
    description:
      "Ensure your documents are accessible to everyone, including those with disabilities, with our PDF Accessibility service. Transform your PDFs to meet international standards and improve user experience for all.",
    src: ACCESSIBILITY,
    color: "#977F6D",
  },

]


const Card = ({ i, title, description, src, color, progress, range, tragetScale }) => {
  const container = useRef(null)
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start end', 'start start']
  })
  const imageScale = useTransform(scrollYProgress, [0, 1], [2, 1])
  const scale = useTransform(progress, range, [1, tragetScale])

    return (
        <div ref={container} className='sticky h-screen flex items-center justify-center top-0 z-10'>
            <motion.div className="relative flex flex-col h-96 w-11/12 md:w-4/5 4k:w-5/6 rounded-3xl p-6 md:p-12 origin-top" style={{ backgroundColor: color, scale, top:`calc(-5vh + ${i * 25}px)` }}>
                <h2 className="text-center text-lg md:text-xl 4k:text-2xl m-0">{title}</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 text-justify h-full md:mt-12 gap-6 md:gap-12">
                    <div className="relative md:top-[10%]">
                        <p className="font-medium first-letter:text-[28px]">{description}</p>
                    </div>
                    <div className="relative h-full rounded-3xl overflow-hidden mt-4 md:mt-0 4k:flex justify-evenly 4k:pr-28">
                        <motion.div style={{ scale: imageScale }}>
                            <img src={src} alt="Services" className="object-cover w-full md:w-96 h-52 rounded-3xl" />
                        </motion.div>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { AnimatedButton } from "./AnimatedButton"
import LOGO from "../assets/images/logo.png"
import { useScroll } from "../context/ScrollContext"

const navLinks = [
  { id: 1, name: "Home", href: "home" },
  { id: 2, name: "Services", href: "services" },
  { id: 3, name: "About", href: "about-us" },
  { id: 4, name: "Career", href: "/career" },
  { id: 5, name: "Contact", href: "contact" },
];

const Navbar = () => {
  const [activeTab, setActiveTab] = useState(navLinks[0].id)
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()
  const { setTargetSection } = useScroll()

  const submenuVariants = {
    open: { height: '100vh', opacity: 1, transition: { duration: 0.3, ease: "easeInOut" } },
    close: { height: 0, opacity: 0, transition: { duration: 0.3, ease: "easeInOut" } }
  }

  const handleNavigation = (href) => {
    if (href.startsWith("#")) {
      const element = document.querySelector(href);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else if (href.startsWith("/")) {
      navigate(href);
    } else {
      setTargetSection(href);
      navigate("/");
    }
  }

  return (
    <header className='fixed px-8 py-3 z-20 w-full bg-transparent backdrop-blur-sm top-0 left-0'>
      <nav className='flex justify-between items-center'>
        <RouterLink to='/'>
          <img src={LOGO} width={100} alt="Eloiacs-Logo" />
        </RouterLink>
        <div className='flex space-x-4 max-lg:hidden'>
          {navLinks.map(item => (
            <button key={item.id}
              onClick={() => {
                setActiveTab(item.id);
                handleNavigation(item.href);
              }}
              className={`${activeTab === item.id ? "" : "hover:text-white/60"} relative rounded-full px-3 py-1.5 uppercase text-sm text-white font-semibold outline-sky-400 transition focus-visible:outline-2 tracking-wider`}
              style={{ WebkitTapHighlightColor: 'transparent' }}
            >
              {activeTab === item.id && (
                <motion.span layoutId="bubble" className='absolute inset-0 z-10 bg-white mix-blend-difference' style={{ borderRadius: 9999 }} transition={{ type: "spring", bounce: 0.2, duration: 0.6 }} />
              )}
              {item.name}
            </button>
          ))}
        </div>
        <div className='hidden max-lg:block' onClick={() => setMenuOpen(!menuOpen)}>
          <AnimatedButton />
        </div>
      </nav>
      <AnimatePresence>
        {menuOpen && (
          <motion.ul className='text-white h-screen' initial="close" animate="open" exit="close" variants={submenuVariants}>
            {navLinks.map(item => (
              <li key={item.id} className='text-2xl text-white mix-blend-difference p-4'>
                <button onClick={() => handleNavigation(item.href)} className='text-xl font-black text-orange-600'>{item.name}</button>
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Navbar;
import React, { useState, useEffect } from 'react';
import PROFILE1 from '../../assets/images/profile2.png';
import PROFILE2 from '../../assets/images/profile1.png';
import PROFILE3 from '../../assets/images/profile3.png';

const testimonialsData = [
    {
        id: 1,
        name: "Jayden Wiggins",
        role: "Web Developer",
        companyName: 'Eloiacs Softwa pvt Ltd',
        image: PROFILE1,
        color: "bg-black",
        textColor: 'text-white',
        text: "I was struggling with my business until I hired Eloiacs for their consulting services. Their team helped me identify areas for improvement and develop a strategic plan that has led to significant growth in revenue. I highly recommend their services to any business owner looking to take their company to the next level.",
        star: 5
    },
    {
        id: 2,
        name: "Cecily Villarreal",
        role: "UI Designer",
        companyName: 'Eloiacs Softwa pvt Ltd',
        image: PROFILE3,
        color: "bg-black",
        textColor: 'text-white',
        text: "I was struggling with my business until I hired Eloiacs for their consulting services. Their team helped me identify areas for improvement and develop a strategic plan that has led to significant growth in revenue. I highly recommend their services to any business owner looking to take their company to the next level.",
        star: 4.5
    },
    {
        id: 3,
        name: "Anusha",
        role: "Web Developer",
        companyName: 'Eloiacs Softwa pvt Ltd',
        image: PROFILE3,
        color: "bg-black",
        textColor: 'text-white',
        text: "I was struggling with my business until I hired Eloiacs for their consulting services. Their team helped me identify areas for improvement and develop a strategic plan that has led to significant growth in revenue. I highly recommend their services to any business owner looking to take their company to the next level.",
        star: 3
    },
    {
        id: 4,
        name: "Ajin",
        role: "PHP Developer",
        companyName: 'Eloiacs Softwa pvt Ltd',
        image: PROFILE2,
        color: "bg-red",
        textColor: 'text-black',
        text: "I was struggling with my business until I hired Eloiacs for their consulting services. Their team helped me identify areas for improvement and develop a strategic plan that has led to significant growth in revenue. I highly recommend their services to any business owner looking to take their company to the next level.",
        star: 3
    },
    {
        id: 5,
        name: "Ajin",
        role: "PHP Developer",
        companyName: 'Eloiacs Softwa pvt Ltd',
        image: PROFILE1,
        color: "bg-black",
        textColor: 'text-white',
        text: "I was struggling with my business until I hired Eloiacs for their consulting services. Their team helped me identify areas for improvement and develop a strategic plan that has led to significant growth in revenue. I highly recommend their services to any business owner looking to take their company to the next level.",
        star: 2.5
    },
];

const Testimonial = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1));
        }, 2500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative bg-white py-10 min-h-96  shadow-md  w-full grid md:grid-cols-3 gap-4">
            <div className="col-span-1 px-4 md:px-8 text-start  items-center">
                <h2 className="text-5xl font-bold mb-4 ">What our <br /> customers think</h2>
                <p className="text-lg mb-4">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minus commodi sint, similique cupiditate possimus suscipit delectus illum eos iure magnam!</p>
                <div className="flex gap-2">
                    {testimonialsData.map((_, index) => (
                        <hr key={index} className={`border-4 ${index === currentIndex ? ' border-y-yellow-700' : 'border-gray-300'} w-12`} />
                    ))}
                </div>
            </div>
            <div className="col-span-2 px-4 md:px-8 relative">
                <TestimonialsView testimonial={testimonialsData[currentIndex]} />
            </div>
        </div>
    );
};

const TestimonialsView = ({ testimonial }) => {
    return (
        <div className={`p-8 ${testimonial.color} ${testimonial.textColor}  `}>
            <div className="flex flex-col items-center text-center">
                <img src={testimonial.image} alt={testimonial.name} className="w-24 h-24 rounded-full mb-4" />
                <h3 className="text-xl font-semibold">{testimonial.name}</h3>

                <div className="flex">
                    {[...Array(Math.floor(testimonial.star))].map((_, i) => (
                        <svg key={i} className="w-6 h-6 text-yellow-500" fill="currentColor" viewBox="0 0 30 20">
                            <path d="M10 15l-5.878 3.09L5.309 10 .5 6.455 7.122 5.09 10 .5l2.878 4.59 6.622 1.365L14.691 10 15.878 18z" />
                        </svg>
                    ))}
                    {testimonial.star % 1 !== 0 && (
                        <svg className="w-6 h-6 text-yellow-500" fill="currentColor" viewBox="0 0 30 20">
                            <path d="M10 15l-5.878 3.09L5.309 10 .5 6.455 7.122 5.09 10 .5l2.878 4.59 6.622 1.365L14.691 10 15.878 18z" fillOpacity="0.5" />
                        </svg>
                    )}
                </div>
                <p className={`${testimonial.textColor} mb-4 text-md text-justify `}>"{testimonial.text}"</p>
            </div>
            <p className="text-sm text-gray-600 mb-2 text-start flex justify-start">{testimonial.role} <br /> {testimonial.companyName}</p>

        </div>
    );
};

export default Testimonial;
import { useEffect, useRef, useState } from 'react';
import FlipText from "../../components/FlipText"
import Button from '../../components/Button';
import DragModal from "../../components/DragModal"
import { heroApp } from "../../constants"
import { AI } from "../../assets/icons"
import './Hero.css';
import Form from './Form';

const Hero = () => {
  const blockContainerRef = useRef(null);
  const blockSize = 50;

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const numCols = Math.ceil(screenWidth / blockSize);
    const numRows = Math.ceil(screenHeight / blockSize);
    const numBlocks = numCols * numRows;

    function createBlocks() {
      const blockContainer = blockContainerRef.current;
      for (let i = 0; i < numBlocks; i++) {
        const block = document.createElement('div');
        block.classList.add('block');
        block.dataset.index = i;
        block.addEventListener('mousemove', highlightRandomNeighbors);
        blockContainer.appendChild(block);
      }
    }

    function highlightRandomNeighbors(event) {
      const blockContainer = blockContainerRef.current;
      const index = parseInt(event.target.dataset.index)
      const neighbors = [
        index - 1,
        index + 1,
        index - numCols,
        index + numCols,
        index - numCols - 1,
        index - numCols + 1,
        index + numCols - 1,
        index + numCols + 1,
      ].filter(i => i >= 0 && i < numBlocks && Math.abs((i % numCols) - (index % numCols)) <= 1);
      event.target.classList.add('highlight');
      setTimeout(() => {
        event.target.classList.remove('highlight');
      }, 500)

      shuffleArray(neighbors).slice(0, 1).forEach((nIndex) => {
        const neighbor = blockContainer.children[nIndex];
        if (neighbor) {
          neighbor.classList.add('highlight');
          setTimeout(() => {
            neighbor.classList.remove('highlight');
          }, 500)
        }
        });
    }

    function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }

    createBlocks();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <div className="blocks-container">
        <div id="blocks" ref={blockContainerRef} />
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 h-screen items-center place-content-center mt-0 md:mt-10 lg:mt-1'>
        <div>
          <div className='ms-3 md:ms-10 4k:ms-24'>
            <FlipText>Enhan<span className='text-orange-600'>c</span>e</FlipText>
          </div>
          <p className='relative ms-3 md:ms-12 md:mt-1 4k:ms-28 text-3xl text-white font-black uppercase sm:text-5xl md:text-6xl 4k:text-9xl pointer-events-none'>Your Future</p>
          <p className='relative ms-3 mt-3 md:ms-12 md:mt-12 4k:ms-32 text-3xl 4k:text-5xl text-white font-black uppercase pointer-events-none'>Innovative &middot; <span className='text-orange-600'>Flexibilty</span> &middot; Growth</p>
          <div className='mt-7 ms-3 md:ms-10 4k:ms-28 4k:mt-10 px-2 flex justify-start'>
            <Button onClick={toggleModal} />
          </div>
        </div>
        <div className='pointer-events-none md:block hidden'>
          <div className='relative left-1/2 flex w-[22rem] 4k:w-[30rem] aspect-square border border-1 rounded-full -translate-x-1/2 scale:75 md:scale-100'>
            <div className='flex w-60 aspect-square m-auto border border-[#252134] rounded-full'>
              <div className='w-[6rem] aspect-square m-auto p-[0.2rem] bg-conic-gradient rounded-full'>
                <div className='flex items-center justify-center w-full h-full bg-2 rounded-full'>
                  <img src={AI} width={40} height={40} alt="AI" />
                </div>
              </div>
            </div>
            <ul>
              {heroApp.map((app, i) => (
                <li key={app.id} className='absolute top-0 left-1/2 h-1/2 -ml-[1.6rem] origin-bottom' style={{ transform: `rotate(${i * 45}deg)` }}>
                  <div className='relative -top-[1.6rem] flex w-[3.2rem] h-[3.2rem] bg-3 border border-white rounded-xl' style={{ transform: `rotate(-${i * 45}deg)` }}>
                    <img className='m-auto' width={app.width} height={app.height} src={app.icon} alt={app.title} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <DragModal open={isModalOpen} setOpen={setIsModalOpen}>
        <Form />
      </DragModal>
    </>
  )
}

export default Hero;
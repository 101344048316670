import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Services from "./pages/services/Services";
import Hero from "./pages/hero/Hero";
import Testimonial from "./pages/testimonial/Testimonial";
import Career from "./pages/career/CareerPage";
import Description from "./pages/career/Description";
import { ScrollProvider, useScroll } from "./context/ScrollContext";
import Lenis from "lenis";
import Footer from "./components/Footer";
import About from "./pages/about-us/About";
import ScrollTop from "./components/ScrollTop";

const MainPage = () => {
  const { targetSection, setTargetSection } = useScroll();
  const location = useLocation();

  useEffect(() => {
    if (targetSection) {
      const element = document.getElementById(targetSection);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setTargetSection(null);
      }
    }
  }, [targetSection, setTargetSection, location]);

  return (
    <>
      <section id="home">
        <Hero />
      </section>
      <section id="services" className='pt-0 md:pt-12'>
        <Services />
      </section>
      <section id="about-us" className='pt-12'>
        <About />
      </section>
      <section>
        <Testimonial />
      </section>
    </>
  );
};

function App() {
  useEffect(() => {
    const lenis = new Lenis();

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
  }, []);

  return (
    <ScrollProvider>
      <Router>
        <Navbar />
        <ScrollTop />
        <Routes>
          <Route path="/" exact element={<MainPage />} />
          <Route path="/career" exact element={<Career />} />
          <Route path="/description/:id" exact element={<Description />} />
        </Routes>
        <Footer />
      </Router>
    </ScrollProvider>
  );
}

export default App;
import React, { useRef, useState, useEffect, useCallback } from "react";
import { slides } from "../../constants";
import { useScroll, motion, useTransform } from "framer-motion";
import { rightArrow } from "../../constants";
import { Link } from "react-router-dom";
import { RunningSlide, CareerExploreData } from "../../constants";

const Career = () => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const [mouseIsFalse, setMouseIsFalse] = useState(true);
  const [scaleIn, setScaleIn] = useState(false);
  const [scaleIndex, setScaleIndex] = useState(null);
  const [screenSize, setScreenSize] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [active, setActive] = useState(false)

  console.log(active);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end end"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.5]);

  const scale1xl = useTransform(scrollYProgress, [0.7, 1], [0, 1]);
  const scale1lg = useTransform(scrollYProgress, [0.6, 1], [0, 1]);
  const scale1md = useTransform(scrollYProgress, [0.5, 1], [0, 1]);
  const scale1sm = useTransform(scrollYProgress, [0.6, 1], [0, 1]);

  const scale2xl = useTransform(scrollYProgress, [0.4, 0.669], [0, 1]);
  const scale2lg = useTransform(scrollYProgress, [0.5, 0.67], [0, 1]);
  const scale2md = useTransform(scrollYProgress, [0.4, 0.65], [0, 1]);
  const scale2sm = useTransform(scrollYProgress, [0.4, 0.65], [0.3, 1]);

  
  const scale3xl = useTransform(scrollYProgress, [0, 0.3], [0, 1]);
  const scale3lg = useTransform(scrollYProgress, [0, 0.3], [0, 1]);
  const scale3md = useTransform(scrollYProgress, [0, 0.3], [0, 1]);
  const scale3sm = useTransform(scrollYProgress, [0, 0.3], [0, 1]);

  const handleMouseEnter = () => {
    setMouseEnter(true);
  };

  const handleMouseLeave = () => {
    setMouseEnter(false);
    setMouseIsFalse(true);
    setActive(false)
  };

  const handleScale = (index) => {
    setScaleIndex(index);
    setScaleIn(true);
  };

  const handleScaleDown = () => {
    setScaleIndex(null);
    setScaleIn(false);
  };

  const handleScreen = useCallback(() => {
    const width = window.innerWidth;
    if (width >= 1280) {
      setScreenSize("xl");
    } else if (width >= 1024) {
      setScreenSize("lg");
    } else if (width >= 768) {
      setScreenSize("md");
    } else {
      setScreenSize("sm");
    }
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setScreenSize(window.innerWidth);
    handleScreen();
  }, [handleScreen]);

  useEffect(() => {
    window.addEventListener("resize", handleScreen);
    return () => {
      window.removeEventListener("resize", handleScreen);
    };
  }, [handleScreen]);

  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.scrollWidth);
    }
  }, []);


  return (
    <section ref={ref}>
      <motion.div
        className="sticky top-0 w-full h-screen overflow-hidden"
        style={{ scale: scale }}
      >
        <div
          className="flex transition-transform ease-out duration-1000"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {slides.map((slide) => (
            <div className="w-full flex-shrink-0" key={slide.id}>
              <img
                src={slide.img}
                alt={`${slide.id} Carousel`}
                className="w-full h-screen object-cover"
              />
            </div>
          ))}
        </div>
        <div className="absolute text-center top-20% md:top-25% lg:top-30% w-full text-slate-50 ">
          <p className="py-10 text-xl md:text-35px font-semibold ">
            BE PART OF OUR TEAM
          </p>
          <p className="px-4 md:px-24 md:py-5 lg:py-10 text-xl leading-10 md:text-2xl lg:text-35px font-medium">
            Join Us In Your Greatest Professional Mission
          </p>
        </div>
      </motion.div>

      <motion.div
        id="ExploreComponent"

        className={`sticky top-0 overflow-hidden bg-white duration-300 h-screen mb-40`}
        style={
          screenSize === "xl"
            ? { scale: scale3xl }
            : screenSize === "lg"
            ? { scale: scale3lg }
            : screenSize === "md"
            ? { scale: scale3md }
            : screenSize === "sm"
            ? { scale: scale3sm }
            : {}
        }
      >
        <div className={``}>
        <p className="font-Quicksand font-semibold text-xl md:text-3xl text-center md:px-70px lg:px-8.8% xl:px-8% 2k:px-9.5% 4k:px-6.8% py-8 2k:pt-14 ">
          Explore Our Internship Programme
        </p>

        <div className={`flex flex-wrap mx-3% md:mx-10% lg:mx-4% xl:mx-5%  lg:ps-6 xl:ps-3 justify-center lg:justify-normal 2xl:ps-9 
        ${active ? "h-screen overflow-y-scroll scrollbar-hide": ""}`}
        onMouseEnter={()=>{setActive(true)}}
        onMouseLeave={()=>{setActive(false)}}
        >
          {CareerExploreData.map((data, index) => (
            <div
              key={data.id}
              className={`flex-flex-100 lg:flex-flex-50 xl:flex-flex-30 px-5 flex flex-col items-center py-4 lg:py-5
                ${scaleIn && index === scaleIndex ? "scaleIn" : "scaleOut"}
              `}
              onMouseEnter={() => handleScale(index)}
              onMouseLeave={() => handleScaleDown(index)}
            >
              <div className="overflow-hidden ">
                <img
                  src={data.url}
                  alt="Department"
                  className={`${
                    scaleIn && index === scaleIndex
                      ? "scale-110 duration-500"
                      : "duration-500"
                  }`}
                />
              </div>

              <div
                className={`px-0 lg:px-10 font-Inter max-w-358px lg:min-h-190px ${
                  scaleIn && index === scaleIndex
                    ? "shadow-bottom-only px-2 lg:px-0"
                    : ""
                }`}
              >
                <p
                  className={`text-md md:text-xl font-semibold py-4 rounded-md tracking-wider ${
                    scaleIn && index === scaleIndex
                      ? "text-career-button-orange duration-700"
                      : "duration-700"
                  }`}
                >
                  {data.title}
                </p>
                <p
                  className={`text-13px leading-6 tracking-tight pb-6 ${
                    scaleIn && index === scaleIndex
                      ? "font-semibold duration-700"
                      : "font-normal duration-700"
                  }`}
                >
                  {data.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        </div>

      </motion.div>

      

      <motion.div
        className="py-12 md:py-32 2k:py-20 h-screen flex px-4 justify-center bg-custom-image items-center sticky top-0 duration-200"
        style={
          screenSize === "xl"
            ? { scale: scale2xl }
            : screenSize === "lg"
            ? { scale: scale2lg }
            : screenSize === "md"
            ? { scale: scale2md }
            : screenSize === "sm"
            ? { scale: scale2sm }
            : {}
        }
      >
        <div className="max-w-605px text-center tracking-wider ">
          <p className="font-Elmessiri font-bold  text-xl md:text-35px py-5 leading-10 text-white">
            Can't find what you're looking for?
          </p>
          <p className="font-Elmessiri text-lg md:text-25px font-medium py-4 text-white">
            Register on our Candidate Portal and get notified when new roles
            that match your skills open up.
          </p>
          <div className="flex justify-center py-3 items-center">
            <a href="https://forms.gle/8nHdJi74vb73aNAXA" target="_blank" rel="noopener noreferrer">
              <button
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="bg-career-orange text-slate-50 w-fit px-4 sm:px-8 py-4 rounded-md shadow-lg text-23px font-semibold box-border flex justify-end items-center "
              >
                <span className="ms-4 flex  items-center font-Elmessiri mt-1 tracking-wider text-nowrap">
                  REGISTER HERE
                </span>
                <img
                  src={rightArrow}
                  alt="Button Right Arrow"
                  height={1}
                  width={30}
                  className={`  ${
                    mouseEnter
                      ? "animation-arrow-button"
                      : mouseIsFalse
                      ? "animation-arrow-buttonHide"
                      : ""
                  }`}
                />
              </button>
            </a>
          </div>
        </div>
      </motion.div>
      
      <motion.div
        className="mt-16 h-screen flex items-center sticky top-0 bg-white "
        style={
          screenSize === "xl"
            ? { scale: scale1xl }
            : screenSize === "lg"
            ? { scale: scale1lg }
            : screenSize === "md"
            ? { scale: scale1md }
            : screenSize === "sm"
            ? { scale: scale1sm }
            : {}
        }
      >
        <div className="overflow-hidden py-8 md:py-12 xl:py-16 mx-5% ">
          <div ref={containerRef} className="relative w-full">
            <motion.div
              className="flex gap-6 w-full md:gap-10 whitespace-nowrap"
              initial={{ x: 0 }}
              animate={{ x: -containerWidth / 2 }}
              transition={{
                duration: 18,
                repeat: Infinity,
                ease: "linear",
              }}
            >
              {RunningSlide.map((item) => (
                <Link
                  to={`/description/${item.id}`}
                  key={item.id}
                  className="flex-flex-10 md:flex-flex-20 lg:flex-flex-31 4k:flex-flex-30"
                >
                  <div className=" px-8 w-full md:px-0">
                    <img
                      src={item.url}
                      alt="Department Slide"
                      className="w-full"
                    />
                    <div className="flex flex-col md:flex-row gap-1 md:gap-4 pt-2">
                      <p className=" text-lg md:text-xl 4k:text-2xl font-bold font-DMsans opacity-100">
                        {item.title}
                      </p>
                      <p className="font-DMsans font-medium text-xs md:text-sm 2k:text-base py-1">
                        Experience: <span>{item.year}</span>
                      </p>
                    </div>
                    <div className="flex justify-between">
                    <div>
                    <p className="font-medium text-base md:text-lg 4k:text-xl pt-4">
                      Work Experience: <span>{item.WorkExperience.length<=20 ?
                       item.WorkExperience : `${item.WorkExperience.slice(0, 20)}`}</span>
                    </p>
                    <p className="font-medium text-base md:text-lg 4k:text-xl">
                      Requirement:{" "}
                      <span>
                        {item.Requirement.length <= 15
                          ? item.Requirement
                          : `${item.Requirement.slice(0, 15)}...`}
                      </span>
                    </p>
                    </div>
                    
                    <Link to={"/login"}>
                  <button className="bg-career-orange text-slate-50 min-w-108px text-base font-semibold tracking-wider rounded-md font-DMsans min-h-43px shadow-bottom-only my- hover:scale-105 ">
                    APPLY
                  </button>
                </Link>
                    </div>
                  
                  </div>
                </Link>
              ))}
            </motion.div>
          </div>
        </div>
      </motion.div>
     
    </section>
  );
};

export default Career;

import REACT from "../assets/images/react.png"
import JAVA from "../assets/images/java.png"
import NODE from "../assets/images/node.png"
import FIGMA from "../assets/images/figma.png"
import PHOTOSHOP from "../assets/images/photoshop.png"
import ACCESSIBILITY from "../assets/images/Accessibility.svg.png"
import DIGITALMARKETING from "../assets/images/Dm.png"
import SEO from "../assets/images/seopng.png"
import carouselSvg1 from "../assets/images/career/career-carousel1.svg"
import carouselSvg2 from "../assets/images/career/career-carousel2.svg"
import carouselSvg3 from "../assets/images/career/career-carousel3.svg"
import SliderSvg1 from "../assets/images/career/running-slide1.svg";
import SliderSvg2 from "../assets/images/career/running-slide2.svg";
import SliderSvg3 from "../assets/images/career/running-slide3.svg";
import SliderSvg4 from "../assets/images/career/running-slide4.svg";
import SliderSvg5 from "../assets/images/career/running-slide5.svg";
import SliderSvg6 from "../assets/images/career/running-slide6.svg";
import GallerySvg1 from "../assets/images/career/career-explore-1.svg";
import GallerySvg2 from "../assets/images/career/career-explore-2.svg";
import GallerySvg3 from "../assets/images/career/career-explore-3.svg";
import GallerySvg4 from "../assets/images/career/career-explore-4.svg";
import GallerySvg5 from "../assets/images/career/career-explore-5.svg";
import GallerySvg6 from "../assets/images/career/career-explore-6.svg";
import DesImg from "../assets/images/career/description.svg"
import arrowRight from "../assets/images/career/ph_arrow-up-light.svg";
import facebookCircle from "../assets/images/career/facebook-circle.svg"
import twitterCircle from "../assets/images/career/twitter-circle-filled.svg"
import instagramSolid from "../assets/images/career/instagram-solid.svg"
import { Email, Facebook, Instagram, LinkedIn, location, Mobile, Twitter, Youtube, colorYoutube, colorFacebook, colorInstagram, colorLinkedIn, colorTwitter } from '../assets/icons/index'


export const heroApp = [
    { id: 1, title: "React", icon: REACT, width: 26, height: 36 },
    { id: 2, title: "Java", icon: JAVA, width: 34, height: 36 },
    { id: 3, title: "Node", icon: NODE, width: 36, height: 28 },
    { id: 4, title: "Figma", icon: FIGMA, width: 34, height: 35 },
    { id: 5, title: "Photoshop", icon: PHOTOSHOP, width: 34, height: 34 },
    { id: 6, title: "Digital Marketing", icon: DIGITALMARKETING, width: 34, height: 34 },
    { id: 7, title: "Accessibility", icon: ACCESSIBILITY, width: 34, height: 34 },
    { id: 8, title: "SEO", icon: SEO, width: 34, height: 35 },
]

export const rightArrow =[arrowRight]

export const slides = [
  {id: 1, img: carouselSvg1},
  {id: 2, img: carouselSvg2},
  {id: 3, img: carouselSvg3},
]

export const RunningSlide = [
  { 
    id:1,
    url: SliderSvg1,
    title: "Web Developer",
    year: "1+year",
    WorkExperience: "1 Year and Above",
    Requirement: "Any Degree....",
    link:"/description"

  },
  { 
    id:2,
    url: SliderSvg2,
    title: "Web Developer",
    year: "1+year",
    WorkExperience: "1 Year and Above",
    Requirement: "Any Degree....",
    link:"/description"
  },
  { 
    id:3,
    url: SliderSvg3,
    title: "Web Developer",
    year: "1+year",
    WorkExperience: "1 Year and Above",
    Requirement: "Any Degree....",
    link:"/description"
  },
  { 
    id:4,
    url: SliderSvg4,
    title: "Web Developer",
    year: "1+year",
    WorkExperience: "1 Year and Above",
    Requirement: "Any Degree....",
    link:"/description"
  },
  { 
    id:5,
    url: SliderSvg5,
    title: "Web Developer",
    year: "1+year",
    WorkExperience: "1 Year and Above",
    Requirement: "Any Degree....",
    link:"/description"
  },
  { 
    id:6,
    url: SliderSvg6,
    title: "Web Developer",
    year: "1+year",
    WorkExperience: "1 Year and Above",
    Requirement: "Any Degree....",
    link:"/description"
  },  
];

export const CareerExploreData = [
  {id:1,url:GallerySvg1,title:"Full Stack Web Development Internship",description:"Full Stack Developer Course with Internship & Placement."},
  {id:2,url:GallerySvg2,title:"Front End Development Internship",description:"Full Stack Developer Course with Internship & Placement."},
  {id:3,url:GallerySvg3,title:"Digital Marketing Certificate Course",description:"Full Stack Developer Course with Internship & Placement."},
  {id:4,url:GallerySvg4,title:"UI/UX Designer Internship",description:"Full Stack Developer Course with Internship & Placement."},
  {id:5,url:GallerySvg5,title:"HR Management",description:"Full Stack Developer Course with Internship & Placement."},
  {id:6,url:GallerySvg6,title:"PDF Accessibility Certificate Internship",description:"Full Stack Developer Course with Internship & Placement."},
 ]

export const JobDescription = [{
  id: 1,
  imgUrl:DesImg,
  title:"PHP Developer (Senior)",
  description1:"Posted 12 days ago",
  description2:" Share Job",
  descriptionImgUrls:[
    {id:1,desUrl:facebookCircle},
    {id:2,desUrl:twitterCircle},
    {id:3,desUrl:instagramSolid},
  ]
  ,
  contents: [
    {
      id: 1,
      title: 'Position Duties and Responsibilities',
      subContents: [
        { id: 1,Sino: 1, text: "Demonstrate strong technical aptitude" },
        { id: 2,Sino: 2, text: "Build and maintain products and features scoped with the team" },
        { id: 3,Sino: 3, text: "Demonstrate self-awareness" },
        { id: 4,Sino: 4, text: "Collaborate with team PM to scope and prioritize technical backlog" },
        { id: 5,Sino: 5, text: "Contribute to team code infrastructure and make recommendations for improvements" },
        { id: 6,Sino: 6, text: "Effectively communicate their individual work" },
        { id: 7,Sino: 7, text: "Provide team and engineering tech mentorship and contribute to overall architecture" },
      ]
    },
    {
      id: 2,
      title: 'Department',
      subContents: [
        { id: 1, text: 'Technology and Services' }
      ]
    },
    {
      id: 3,
      title: 'Open Positions',
      subContents: [
        { id: 1, text: '10' }
      ]
    },
    {
      id: 4,
      title: 'Skills Required',
      subContents: [
        { id: 1, text: 'PHP CodeIgniter Framework, MySQL, ClickHouse' }
      ]
    },
    {
      id: 5,
      title: 'Role',
      subContents: [
        { id: 1, text: '' }
      ]
    },
    {
      id: 6,
      title: 'Required Experience, Skills, and Education',
      subContents: [
        { id: 1,Sino: 1, text: "7+ years of experience" },
        { id: 2,Sino: 2, text: "An analytical mind with a strong grasp of algorithms and data structures" },
        { id: 3,Sino: 3, text: "Experience with PHP - CodeIgniter Framework, MySQL, ClickHouse" },
        { id: 4,Sino: 4, text: "Interest and ability to learn new languages and technologies as needed" },
        { id: 5,Sino: 5, text: "Interest in working on an agile/lean team" },
        { id: 6,Sino: 6, text: "Experience or interest in developing and maintaining distributed systems" },
        { id: 7,Sino: 7, text: "Prior experience handling reliability, troubleshooting, and day-to-day deployment/monitoring operations for the products that you developed" },
        { id: 8,Sino: 8, text: "A proven track record of self-motivation and determination to learn" },
        { id: 9,Sino: 9, text: "Working proficiency and communication skills in verbal and written English" },
        { id: 10,Sino: 10, text: "Willingness to learn from and to teach your colleagues" },
      ]
    },
    {
      id: 7,
      title: 'Location',
      subContents: [
        { id: 1, text: 'Nagercoil, Tamil Nadu, India' }
      ]
    },
    {
      id: 8,
      title: 'Education/Qualification',
      subContents: [
        { id: 1, text: 'Any UG degree' }
      ]
    },
    {
      id: 9,
      title: 'Desired Skills',
      subContents: [
        { id: 1, text: 'PHP CodeIgniter Framework, MySQL, ClickHouse' }
      ]
    },
    {
      id: 10,
      title: 'Years of Experience',
      subContents: [
        { id: 1, text: '7 to 11' }
      ]
    },
    {
      id: 11,
      title: 'Designation',
      subContents: [
        { id: 1, text: 'Senior Software Engineer' }
      ]
    },
  ]
}];


export const DesImage = [{id:1,imgUrl:DesImg}]

export const footerData = [
  { id: '1', title: 'Quick links' },
  { id: '2', title: 'Services' },
  { id: '3', title: 'Support Services' },
  { id: '4', title: 'Contact With Us' },
]

export const footerSubdata = {
  1: [
      { id: '1', subtitle: 'Home' },
      { id: '2', subtitle: 'About Us' },
      { id: '3', subtitle: 'Blog' },
      { id: '4', subtitle: 'Career' },
  ],
  2: [
      { id: '1', subtitle: 'Software Development' },
      { id: '2', subtitle: 'Data Entry' },
      { id: '3', subtitle: 'E-Books Conversion' },
      { id: '4', subtitle: 'PDF Accessibility' },
      { id: '5', subtitle: 'Digital Marketing' },
      { id: '6', subtitle: 'Mobile App Development' },
      { id: '7', subtitle: 'Web Designing' },
  ],
  3: [
      { id: '1', subtitle: 'Web Accessibility' },
      { id: '2', subtitle: 'Word Accessibility' },
      { id: '3', subtitle: 'E-Publishing' },
      { id: '4', subtitle: 'Type Setting' },
      { id: '5', subtitle: 'Copy Editing' },
      { id: '6', subtitle: 'Email Marketing' },
  ],
  4: [
      { id: '1', icon: Email, subtitle: 'info@eloiacs.com' },
      { id: '2', icon: Mobile, subtitle: '+91 9486 00 06 07, +91 9486 00 06 07' },
      { id: '3', icon: location, subtitle: 'Eloiacs Softwa Pvt ltd, SBI Bank Building,Ramanputhur, Nagercoil,Tamil Nadu 629002.' },

  ],
}

export const footerSocialMediaData = [
  { id: '1', icon: Facebook, alterIcon: colorFacebook, title: 'Facebook', userId: 'Eloiacs Softwa' },
  { id: '2', icon: Instagram, alterIcon: colorInstagram, title: 'Instagram', userId: 'eloiacs_softwa_pvt.ltd' },
  { id: '3', icon: Youtube, alterIcon: colorYoutube, title: 'Youtube', userId: 'Eloiacs Softwa PVT.LTD.' },
  { id: '4', icon: Twitter, alterIcon: colorTwitter, title: 'Twitter', userId: '@Eloiacs_softwa' },
  { id: '5', icon: LinkedIn, alterIcon: colorLinkedIn, title: 'LinkedIn', userId: 'Eloiacs Softwa Private Limited' },
]
import { useRef, useState } from "react"
import { motion } from "framer-motion"

const CYCLES_PER_LETTER = 2
const SHUFFLE_TIME = 50

const CHARS = "!@#$%^&*():{};|,.<>/?"

const AnimatedText = ({ children }) => {
    const intervalRef = useRef(null)
    const [text, setText] = useState(children)
    const [hasShuffled, setHasShuffled] = useState(false)

    const shuffle = () => {
        let pos = 0
        intervalRef.current = setInterval(() => {
            const shuffled = children.split("").map((char, index) => {
                if (pos / CYCLES_PER_LETTER > index) {
                    return char
                }

                const randomCharIndex = Math.floor(Math.random() * CHARS.length)
                const randomChar = CHARS[randomCharIndex]

                return randomChar
            }).join("")

            setText(shuffled)
            pos++

            if (pos >= children.length * CYCLES_PER_LETTER) {
                stopShuffle()
            }
        }, SHUFFLE_TIME)
    }

    const stopShuffle = () => {
        clearInterval(intervalRef.current || undefined)
        setText(children)
        setHasShuffled(true)
    }

    const handleViewportEnter = () => {
        if(!hasShuffled) {
            shuffle()
        }
    }

    return (
        <motion.div whileTap={{ scale: 0.975 }} onViewportEnter={handleViewportEnter} className='group relative overflow-hidden px-4 py-2 font-black uppercase text-3xl sm:text-7xl md:text-8xl 4k:text-10xl transition-colors pointer-events-none'>
            <div className='relative z-10 font-black'>
                <span>{text}</span>
            </div>
        </motion.div>
    )
}

export default AnimatedText
import React, { useEffect, useRef } from 'react';
import { footerData, footerSubdata, footerSocialMediaData } from '../constants/index';
import one from '../assets/images/1.png';
import two from '../assets/images/2.png';
import three from '../assets/images/3.png';
import four from '../assets/images/4.png';
import five from '../assets/images/5.png';
import six from '../assets/images/6.png';
import sevenWonders from '../assets/images/7 wonders.jpg'


const images = [
    one,
    two,
    three,
    four,
    five,
    six,
];

const Footer = () => {


    return (
        <>
        <div className="relative bg-slate-50" id='contact'>
                    {/* <img className='absolute z-50 hidden md:block absolute top-32 left-0 w-full h-10 opacity-40 z-0' src={sevenWonders} alt="Seven Wonders" /> */}
                    <div className=" overflow-hidden w-full  ">
                        <Brandcarosel />
                    </div>
                </div>
            <div className="absolute  max-h-[100vh]">
                
                <footer className=' bg-[#060708] text-[#ffffff99] px-8 py-3 z-10' id='Footer'>
                    <div className='pt-3 grid sm:grid-cols-2 md:grid-cols-4'>
                        {footerData.map((data) => (
                            <div className="mb-7 font-DMsans text-19px" key={data.id}>
                                <div className="mb-2 underline-offset-2 underline text-[#F6831B]">{data.title}</div>
                                <div className="flex flex-col">
                                    {footerSubdata[data.id] && footerSubdata[data.id].map((sub) => (
                                        <div className="font-Inter font-normal py-2 relative group hover:text-[#F6831B] text-13px flex justify-start items-start gap-2" key={sub.id}>
                                            <p className="inline-block hover:tracking-wider">{sub.subtitle}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="border md:w-3/5 border-y-white"></div>
                    <div className="text-start font-Inter font-normal text-18px pt-1">
                        <h4>Follow us for the latest updates</h4>
                    </div>
                    <div className="grid sm:flex flex-wrap gap-3 justify-between py-10">
                        {footerSocialMediaData.map((items) => (
                            <div key={items.id}>
                                <div className="flex gap-2 group">
                                    <img className='p-1 group-hover:block' src={items.icon} alt={items.title} />

                                    <div>
                                        <p className='font-normal md:text-12px font-Inter text-10px text-wrap md:text-nowrap uppercase'>{items.title}</p>
                                        <p className='font-normal md:text-12px font-Inter text-10px text-wrap md:text-nowrap capitalize hover:text-[#F6831B]'>{items.userId}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex items-center text-center border-b-4 pb-1 border-y-white">
                        2024 copyrights reserved by ELOIACS
                    </div>
                </footer>
            </div>
        </>
    );
};

export default Footer;
const Brandcarosel = () => {
    const carouselRef = useRef(null);

    useEffect(() => {
        if (carouselRef.current) {
            const totalWidth = carouselRef.current.scrollWidth;
            carouselRef.current.style.animation = `brandNameScroll ${totalWidth / 50}s linear infinite`;
        }
    }, []);

    return (
        <div className="relative px-3 py-6 overflow-hidden">
            <div className="absolute top-0 left-0 w-24 h-full bg-gradient-to-r from-transparent to-slate-50"></div>
            <div className="absolute top-0 right-0 w-24 h-full bg-gradient-to-l from-transparent to-slate-50"></div>
            <div
                ref={carouselRef}
                className="flex gap-3 animate-marquee"
                style={{ width: 'max-content' }}
            >
                {images.concat(images).map((src, index) => (
                    <div key={index} className="min-w-max object-cover">
                        <img src={src} alt={`Brand ${index % images.length + 1}`} className="w-48 h-24" />
                    </div>
                ))}
            </div>
        </div>
    );
};
import { useRef, useState } from "react"
import { motion } from "framer-motion"

const TARGET_TEXT = "Get In Touch"
const CYCLES_PER_LETTER = 2
const SHUFFLE_TIME = 50

const CHARS = "!@#$%^&*():{};|,.<>/?"

const Button = ({ onClick }) => {
    const intervalRef = useRef(null)
    const [text, setText] = useState(TARGET_TEXT)

    const shuffle = () => {
        let pos = 0
        intervalRef.current = setInterval(() => {
            const shuffled = TARGET_TEXT.split("").map((char, index) => {
                if (pos / CYCLES_PER_LETTER > index) {
                    return char
                }

                const randomCharIndex = Math.floor(Math.random() * CHARS.length)
                const randomChar = CHARS[randomCharIndex]

                return randomChar
            }).join("")

            setText(shuffled)
            pos++

            if (pos >= TARGET_TEXT.length * CYCLES_PER_LETTER) {
                stopShuffle()
            }
        }, SHUFFLE_TIME)
    }

    const stopShuffle = () => {
        clearInterval(intervalRef.current || undefined)
        setText(TARGET_TEXT)
    }

    return (
        <motion.button whileHover={{ scale: 1.025 }} whileTap={{ scale: 0.975 }} onMouseEnter={shuffle} onMouseLeave={stopShuffle} onClick={onClick} className='group relative overflow-hidden rounded-lg border border-neutral-500 bg-neutral-700 px-4 py-2 font-mono font-medium uppercase 4k:text-3xl text-neutral-300 transition-colors hover:text-orange-600'>
            <div className='relative z-10 font-black'>
                <span>{text}</span>
            </div>
            <motion.span initial={{ y: "100%" }} animate={{ y: "-100%" }} transition={{ repeat: Infinity, repeatType: 'mirror', duration: 1, ease: "linear" }} className="duration-300 absolute inset-0 z-0 scale-125 bg-gradient-to-t from-orange-600/0 from-40% via-orange-600/100 to-indigo-400/0 to-60% opacity-0 transition-opacity group-hover:opacity-100" />
        </motion.button>
    )
}

export default Button
import React, { useState } from 'react';
import Animation from './Animation';
import emailjs from 'emailjs-com';

const Form = () => {
    const [formValues, setFormValues] = useState({
        user_name: '',
        user_email: '',
        user_phone: '',
        user_description: ''
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (!formValues.user_name) newErrors.user_name = 'Name is required';
        if (!formValues.user_email) {
            newErrors.user_email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formValues.user_email)) {
            newErrors.user_email = 'Invalid email address';
        }
        if (!formValues.user_phone) {
            newErrors.user_phone = 'Phone number is required';
        } else if (!/^[0-9]{10}$/.test(formValues.user_phone)) {
            newErrors.user_phone = 'Invalid phone number';
        }
        if (!formValues.user_description) {
            newErrors.user_description = 'Description is required';
        } else if (formValues.user_description.split(' ').length > 300) {
            newErrors.user_description = 'Description must be 300 words or less';
        }
        return newErrors;
    };

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value
        });
    };

    const handleDescriptionChange = (e) => {
        if (e.target.value.split(' ').length <= 300) {
            handleChange(e);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            emailjs.send('service_dn4qoce', 'template_1wbho6z', formValues, 'hv0kvtKmAkvCYQvRb')
                .then((response) => {
                    console.log('Email sent successfully!', response.text);
                    setFormValues({
                        user_name: '',
                        user_email: '',
                        user_phone: '',
                        user_description: ''
                    });
                })
                .catch((error) => {
                    console.error('Email send error:', error);
                });
        }
    };

    return (
        <div className='px-4 md:px-40 py-10'>
            <div className='w-full bg-black opacity-80 text-white p-6 md:p-12'>
                <h4 className='md:text-3xl text-xl text-start md:text-center font-black tracking-widest'>Work with the best</h4>
                <h2 className='pt-3 flex justify-center'>Contact US</h2>
                <p className='pt-3 flex pb-10 justify-center'>For any other inquiries, please fill out the form below, and we'll get back to you as soon as possible:</p>

                <div className="lg:grid md:grid-cols-3">
                    <div className='md:pr-6'>
                        <div className='relative lg:bottom-14 md:right-24 hidden lg:block'>
                            <Animation />
                        </div>
                    </div>
                    
                    <div className="md:col-span-2 grid space-y-6 text-black">
                        <form onSubmit={handleSubmit}>
                            <div className='pb-5'>
                                <input
                                    className='w-full px-4 py-2 outline-none'
                                    type="text"
                                    name="user_name"
                                    placeholder='Name'
                                    onChange={handleChange}
                                    value={formValues.user_name}
                                />
                                {errors.user_name && (
                                    <div className="text-red-500">{errors.user_name}</div>
                                )}
                            </div>
                            <div className='pb-5'>
                                <input
                                    className='w-full px-4 py-2 outline-none'
                                    type="email"
                                    name="user_email"
                                    placeholder='Email'
                                    onChange={handleChange}
                                    value={formValues.user_email}
                                />
                                {errors.user_email && (
                                    <div className="text-red-500">{errors.user_email}</div>
                                )}
                            </div>
                            <div className='pb-5'>
                                <input
                                    className='w-full px-4 py-2 outline-none'
                                    type="tel"
                                    name="user_phone"
                                    placeholder='Mobile No'
                                    onChange={handleChange}
                                    value={formValues.user_phone}
                                />
                                {errors.user_phone && (
                                    <div className="text-red-500">{errors.user_phone}</div>
                                )}
                            </div>
                            <div className='pb-5'>
                                <textarea
                                    className='w-full min-h-24 px-4 py-2 outline-none'
                                    name="user_description"
                                    placeholder='Description'
                                    onChange={handleDescriptionChange}
                                    value={formValues.user_description}
                                    onKeyDown={(e) => {
                                        if (e.target.value.split(' ').length >= 300 && e.key !== 'Backspace') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                {errors.user_description && (
                                    <div className="text-red-500">{errors.user_description}</div>
                                )}
                            </div>
                            <button className='border-2 px-10 py-2 text-yellow-700 rounded-2xl text-md font-semibold tracking-widest' type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Form;

import AnimatedText from '../../components/AnimatedText'
import { motion } from 'framer-motion'

const About = () => {
  return (
    <div className='relative pointer-events-none mb-10 md:mb-36'>
        <div className='ms-8 4k:ms-24 text-stroke-orange text-transparent'>
            <AnimatedText>About us</AnimatedText>
        </div>
        <motion.div className='text-center text-white text-xl sm:text-3xl md:text-4xl lg:text-5xl 4k:text-7xl font-mono font-black mt-7 4k:mt-16' whileInView={{ opacity: 1, y: 0 }} initial={{ opacity: 0, y: 100 }} transition={{ duration: 0.5, delay: 1 }} viewport={{ once: true }}>
            A global leader in next-generation digital<br />services and consulting
        </motion.div>
        <motion.div className='grid grid-cols-1 md:grid-cols-3 text-white mt-5 md:mt-16' whileInView={{ opacity: 1, y: 0 }} initial={{ opacity: 0, y: 100 }} transition={{ duration: 0.5, delay: 1.5 }} viewport={{ once: true }}>
            <div className='text-center'>
                <p className='text-xl sm:text-3xl md:text-4xl lg:text-5xl 4k:text-7xl font-black font-mono'>10<sup>+</sup></p>
                <span className='text-xs md:text-sm 4k:text-xl text-gray-400 font-medium font-mono'>countries where we have<br/>trusting clients</span>
            </div>
            <div className='text-center mt-4 md:mt-0'>
                <p className='text-xl sm:text-3xl md:text-4xl lg:text-5xl 4k:text-7xl font-black font-mono'>200<sup>+</sup></p>
                <span className='text-xs md:text-sm 4k:text-xl text-gray-400 font-medium font-mono'>Satisfied Clients</span>
            </div>
            <div className='text-center mt-4 md:mt-0'>
                <p className='text-xl sm:text-3xl md:text-4xl lg:text-5xl 4k:text-7xl font-black font-mono'>700<sup>+</sup></p>
                <span className='text-xs md:text-sm 4k:text-xl text-gray-400 font-medium font-mono'>Training(days) conducted for<br/>employees, globally</span>
            </div>
        </motion.div>
    </div>
  )
}

export default About
